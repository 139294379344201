<template>
  <ValidationObserver #default="{ validate }" slim>
    <modal
      class="choose-document-type-modal"
      name="chooseDocumentType"
      height="auto"
      :width="1040"
      :minHeight="100"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="!isUploading"
      @closed="$emit('closed')"
    >
      <h4 class="title m-b-15">Choose the specific document type</h4>
      <div class="separator m-b-35"></div>
      <ol class="file-list">
        <li class="m-b-10" v-for="(document, index) in documentsList" :key="index">
          <div class="file">
            <div class="text m-r-5 m-l-5 file-text-wrapper">
              <div class="file-text">
                <div class="list-title meta" v-if="!index">Name</div>
                <p class="file-name m-b-0">{{ document.file.name | fileName }}</p>
                <span class="description">{{ document.file.size | fileSize }}</span>
              </div>
              <button class="circular ui icon secondary button small remove-btn-cross" type="button" @click="documentsList.splice(index, 1)">
                <i class="icon material-icons notification-icon">close</i>
              </button>
            </div>
            <ValidationProvider
              class="dropdown-holder"
              :rules="{ required: true }"
              name=" "
              :vid="`${document.file.name.replace(/\s/g, '_')}-title`"
              #default="{ errors }"
              tag="div"
              v-if="document.type"
            >
              <div class="list-title meta" v-if="!index">Title</div>
              <div class="ui form" v-if="document.type === 'PitchDeck'">
                <input type="text" :value="document.title" :name="`${document.file.name.replace(/\s/g, '_')}-title`" readonly disabled />
              </div>
              <div class="ui form" v-else-if="document.type === 'Other'">
                <input type="text" v-model="document.title" :name="`${document.file.name.replace(/\s/g, '_')}-title`" />
              </div>
              <div class="ui form" v-else>
                <select class="ui selection dropdown" :disabled="isUploading" v-model="document.title">
                  <option
                    v-for="option in getDocumentTitleOptions(document.type)"
                    :key="option.value"
                    :value="option.value"
                    :name="`${document.file.name.replace(/\s/g, '_')}-title`"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
              <FieldError v-bind="{ errors }" />
            </ValidationProvider>
            <button class="btn btn-warning m-l-10 remove-btn" type="button" @click="documentsList.splice(index, 1)">Remove</button>
            <div class="uploading-status-container m-l-10">
              <spinner v-if="document.status === 'uploading'" />
              <i class="text-primary material-icons" v-if="document.status === 'uploaded'">check</i>
              <i class="text-danger material-icons" v-if="document.status === 'error'">close</i>
            </div>
          </div>
          <div class="file-error-wrapper text-danger" v-if="document.errorResponse && document.errorResponse.data">{{ document.errorResponse.data.message }}</div>
        </li>
      </ol>
      <div class="row center-xs m-b-35">
        <button v-promise-btn class="btn btn-primary action-button" @click="onPress(validate)">
          <template>Upload Documents</template>
        </button>
      </div>
      <div class="row center-xs">
        <button class="btn btn-primary btn-border action-button-close" type="button" @click="close" v-promise-btn>
          <template>Close</template>
        </button>
      </div>
    </modal>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import FieldError from '@/components/forms/FieldError'
import { companyGetters } from '@/store/modules/company/routines'
import { sendDocument } from '@/api/document'
import { documentTypes, documentTypesDic, amplitudeEvents } from '@/utils/constants'

export default {
  name: 'chooseDocumentType',
  components: {
    ValidationObserver,
    ValidationProvider,
    FieldError
  },
  props: ['documents', 'companyId', 'documentCategory', 'uploadedDocumentsList'],
  data() {
    return {
      documentTypesDic,
      documentsList: null,
      isUploading: false,
      hasError: false
    }
  },
  computed: {
    ...mapGetters({
      companyCurrent: companyGetters.COMPANY_CURRENT
    }),
    documentTypes() {
      return this.companyCurrent.incorporationCountry === 'Ireland' ? documentTypes : documentTypes.map(dt => ({ ...dt, titles: dt.titles.filter(title => !title.isIreland) }))
    }
  },
  methods: {
    getDocumentTitleOptions(documentTypeValue) {
      const documentType = this.documentTypes.find(({ value }) => value === documentTypeValue)
      return documentType ? documentType.titles : []
    },
    onPress(validate) {
      this.$nextTick(() => {
        return validate()
          .then(result => {
            if (result) {
              this.isUploading = true
              this.hasError = false
              return this.uploadDocumentsOneByOne()
            } else {
              this.hasError = true
            }
          })
          .then(() => {
            this.isUploading = false
            if (this.hasError) return
            this.$emit('uploaded', this.documentsList)
            // setTimeout - for better UX (Animations)
            setTimeout(this.close, 300)
          })
          .catch(err => {
            this.isUploading = false
            console.log(err)
          })
      })
    },
    async uploadDocumentsOneByOne(index = 0) {
      if (index >= this.documentsList.length) return
      const doc = this.documentsList[index]
      this.trackAmplitudeDocEvent(doc)
      const params = {
        companyId: this.companyId,
        type: doc.type === 'PitchDeck' ? 'FundingDocuments' : doc.type,
        title: doc.title
      }
      const formData = new FormData()
      formData.append('file', doc.file)
      this.$set(doc, 'status', 'uploading')
      this.$set(doc, 'errorResponse', '')
      await sendDocument(formData, params)
        .then(() => {
          doc.status = 'uploaded'
        })
        .catch(e => {
          doc.status = 'error'
          doc.errorResponse = e.response
        })
      return this.uploadDocumentsOneByOne(index + 1)
    },
    trackAmplitudeDocEvent(doc) {
      /** Amplitude Analytics */
      this.$ma.addItem({
        'Document Uploaded Counter': 1
      })
      this.$ma.trackEvent({
        eventType: amplitudeEvents.fundingCheckout.UPLOAD_DOCS,
        eventProperties: {
          'Document Type': this.documentCategory,
          'Document Name': doc.title
        }
      })
    },
    close() {
      if (this.hasError) {
        this.$emit('uploaded', this.documentsList)
      }
      this.$modal.hide('chooseDocumentType')
    }
  },
  watch: {
    documents() {
      this.documentsList = this.documents.map(doc => (doc.type ? doc : { ...doc, type: this.documentCategory, title: this.documentCategory === 'PitchDeck' ? 'Pitch Deck' : '' }))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/swoop/variables';

.dropdown-holder {
  margin-left: 15px;
  @media only screen and (max-width: $breakpoint-sm-max) {
    width: 100%;
    position: relative;
    margin-top: 8px;
    margin-left: 5px;
    padding-top: 10px;
  }
}

.file-text-wrapper {
  @media only screen and (max-width: $breakpoint-sm-max) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.ui.form {
  .dropdown,
  .dropdown + .text-danger {
    width: 160px;
    white-space: nowrap;
    @media only screen and (max-width: $breakpoint-sm-max) {
      min-width: 100%;
      width: 100%;
    }
  }
}

.dropdown-title {
  input[type='text'] {
    max-width: 100%;
  }
}

.remove-btn-cross {
  display: none !important;
  @media only screen and (max-width: $breakpoint-sm-max) {
    display: block !important;
  }
}
.list-title.meta {
  @media only screen and (max-width: $breakpoint-sm-max) {
    display: none;
  }
}
.remove-btn {
  display: inline-block;
  @media only screen and (max-width: $breakpoint-sm-max) {
    display: none;
  }
}

.dropdown {
  &.dropdown-title {
    li {
      width: 300px;
      max-width: 90vw;
    }
  }
  .angle-down-icon {
    display: inline-block;
    transition: transform 150ms ease-in-out;
    margin-left: 5px;
  }
  &.open {
    .angle-down-icon {
      transform: rotate(180deg);
    }
  }
  .dropdown-button {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $color-primary;
    cursor: pointer;
    .dropdown-button-text {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  li {
    width: 200px;
    font-size: 14px;
    color: var(--color-primary-500);
    &:hover {
      background-color: $dropdown-link-active-bg;
    }
    .radio {
      padding: 10px;
      /deep/ {
        .holder {
          display: flex;
          align-items: center;
          .text {
            white-space: normal;
          }
        }
      }
    }
  }
}

.file-list {
  padding-left: 20px;
  margin: 0 0 38px;
}

.file {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  color: var(--color-primary-500);
  position: relative;
  @media only screen and (max-width: $breakpoint-sm-max) {
    flex-wrap: wrap;
  }
  .list-title {
    position: absolute;
    top: -20px;
  }
  .index {
    opacity: 0.6;
  }
  .description {
    opacity: 0.6;
  }
}

.separator:before,
.separator:after {
  width: 100%;
  margin: 0;
}

.uploading-status-container {
  height: 25px;
}
.ui {
  &.form {
    select,
    input[type='text'] {
      width: 410px;
    }
    select {
      background-position: 98% 50%;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>');
    }
  }
}
.action-button {
  min-width: 196px;
  &-close {
    min-width: 112px;
  }
}
</style>
