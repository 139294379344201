<template>
  <div class="survey-documents">
    <DropFile v-bind="{ name, multiple, rules }" @change="dropFileChangeHandler" ref="dropFile" />
    <DocumentsList :filesList="filteredFilesList" @change="emitValueChange" />
    <ChooseDocumentType
      :documents="uploadDocuments"
      :companyId="companyId"
      @uploaded="emitValueChange"
      @closed="$refs.dropFile.renew()"
      :documentCategory="documentCategory"
      :uploadedDocumentsList="filteredFilesList"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import DropFile from '@/components/forms/DropFile'
import DocumentsList from '@/components/DocumentsList'
import ChooseDocumentType from './ChooseDocumentType'

export default {
  name: 'SurveyDocuments',
  props: {
    value: {
      required: true
    },
    name: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    rules: {
      type: [Object, String],
      required: true
    },
    documentCategory: {
      type: String,
      required: true
    },
    filesList: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      uploadDocuments: []
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    }),
    filteredFilesList () {
      return this.filesList[this.documentCategory]
    }
  },
  components: {
    DropFile,
    DocumentsList,
    ChooseDocumentType
  },
  methods: {
    dropFileChangeHandler (fileList) {
      this.uploadDocuments = fileList
      this.$modal.show('chooseDocumentType')
    },
    emitValueChange () {
      this.$emit('input')
    }
  },
  watch: {
    documentCategory () {
      this.$refs.dropFile.$refs.validationProvider.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/swoop/variables';

.survey-documents {
  @media only screen and (max-width: $breakpoint-sm-max) {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .documents-list {
    border-top: 1px solid var(--color-primary-100);
    @media only screen and (max-width: $breakpoint-sm-max) {
      max-width: 100%;
    }
  }
}
</style>
